import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ArticleCard from './ArticleCard'
import { sampleSize, orderBy } from 'lodash'

const AlsoReadTemplate = (props) => {
  // this is to avoid this issue: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  const { data = {} } = props
  const { edges: allPosts } = data.allStrapiArticle
  const posts = orderBy(
    sampleSize(allPosts, 8),
    (article) => article.node.publishDate,
    ['desc']
  )

  return (
    <>
      <h2 className="is-size-3">اقرأ أيضا:</h2>
      <div className="columns is-multiline is-gapless">
        {posts.map((article) => (
          <ArticleCard
            key={article.id}
            hideExcerpt={true}
            titleStyle="is-size-5"
            className="column is-full"
            author={article.node.author}
            post={article.node}
          />
        ))}
      </div>
    </>
  )
}

const AlsoRead = () => (
  <StaticQuery
    query={graphql`
      query AlsoReadQuery {
        allStrapiArticle(
          limit: 20
          sort: { fields: publishDate, order: DESC }
        ) {
          edges {
            node {
              excerpt
              id
              isFeatured
              publishDate
              strapiId
              slug
              title
              author {
                id
                name
                screenName
              }
            }
          }
        }
      }
    `}
    render={(data) => <AlsoReadTemplate data={data} />}
  />
)

AlsoReadTemplate.propTypes = {
  data: PropTypes.shape({
    allStrapiArticle: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AlsoRead
