import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import showdown from 'showdown'
import { formatDate } from '../utils'
import ShareBlock from '../components/ShareBlock'
import { getRandomBackground } from '../utils'
import ArticleCard from '../components/articles/ArticleCard'
import AlsoRead from '../components/articles/AlsoRead'
import PostImage from '../components/articles/PostImage'

export const BlogPostTemplate = (props) => {
  const {
    content,
    contentComponent,
    tags,
    title,
    helmet,
    author,
    post,
    otherArticles,
    location,
  } = props

  const PostContent = contentComponent || Content
  const contentHtml = new showdown.Converter({
    simpleLineBreaks: true,
  }).makeHtml(content)

  const hasImage = post.image && post.image.url

  return (
    <>
      {helmet || ''}

      <div
        className="full-width-image-container"
        style={{
          backgroundImage: `url(${getRandomBackground()})`,
          height: '80px',
          marginBottom: '10',
          marginTop: '0',
        }}
      ></div>

      <div className="container">
        <div className="columns is-gapless">
          <div className="column is-12">
            <div className="column is-12">
              <h1 className="title is-size-3-mobile is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>

              <span className="subtitle is-size-6 is-block">
                {author && (
                  <Link to={`/authors/${author.screenName}`}>
                    {author.name}
                  </Link>
                )}

                {' - '}
                <span className="date">{formatDate(post.publishDate)}</span>
              </span>
              {hasImage && (
                <div className="has-text-centered">
                  <PostImage post={post} />
                </div>
              )}

              <PostContent
                className="has-text-justified"
                content={contentHtml}
              />

              <ul className="content taglist">
                {tags &&
                  tags.map((tag) => (
                    <Link key={tag.id} to={`/tags/${tag.tagName}`}>
                      <li
                        style={{
                          color: 'white',
                          backgroundColor: 'black',
                          display: 'inline-block',
                          marginLeft: '20px',
                          padding: '10px',
                          marginTop: '20px',
                          borderRadius: '25px',
                        }}
                      >
                        {tag.tagName}
                      </li>
                    </Link>
                  ))}
              </ul>

              <hr />
              <ShareBlock
                url={location.href}
                slug={post.slug}
                text={`${post.title} | ${author && author.name} | @SudanSSC `}
              />
              {author && (
                <div className="column is-12">
                  <hr />
                  <h2 className="is-size-4 is-size-5-mobile">
                    الكاتب:{' '}
                    <Link to={`/authors/${author.screenName}`}>
                      {author.name}
                    </Link>
                  </h2>
                  {author.bio && author.bio.length > 10 ? author.bio : ''}

                  {otherArticles.edges.map((edge) => {
                    const { node: article } = edge
                    return (
                      <ArticleCard
                        key={article.id}
                        hideExcerpt
                        post={article}
                      />
                    )
                  })}
                  <div className="conent">
                    <Link className="link" to={`/authors/${author.screenName}`}>
                      اقرأ بقية المقالات
                    </Link>
                  </div>
                </div>
              )}
              <hr />
              <AlsoRead />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  tags: PropTypes.array,
  author: PropTypes.object,
  post: PropTypes.shape({
    image: PropTypes.object,
    publishDate: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  otherArticles: PropTypes.object,
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
}

const BlogPost = ({ data, location }) => {
  const { strapiArticle: post, allStrapiArticle: otherAuthorArticles } = data

  return (
    <Layout>
      <BlogPostTemplate
        post={post}
        location={location}
        content={post.body}
        contentComponent={HTMLContent}
        description={post.excerpt}
        helmet={
          <Helmet titleTemplate="%s | مركز الدراسات السودانية">
            <title>{`${post.title}`}</title>
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="ar_TN" />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.excerpt} />
            <meta property="og:url" content={location.href} />
            <meta name="description" content={post.excerpt} />
          </Helmet>
        }
        tags={post.tags}
        title={post.title}
        author={post.author}
        otherArticles={otherAuthorArticles}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    strapiArticle: PropTypes.object,
    allStrapiArticle: PropTypes.object,
  }),
  location: PropTypes.object,
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $screenName: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      strapiId
      body
      excerpt
      publishDate(formatString: "MMMM DD, YYYY")
      strapiId
      slug
      title
      author {
        name
        bio
        screenName
      }
      tags {
        tagName
      }
      updated_at
      created_at
      image {
        url
        formats {
          small {
            url
          }
        }
      }
    }

    allStrapiArticle(
      limit: 5
      sort: { fields: publishDate, order: DESC }
      filter: { author: { screenName: { eq: $screenName } } }
    ) {
      edges {
        node {
          excerpt
          id
          title
          slug
          body
          author {
            id
            name
            screenName
          }
        }
      }
    }
  }
`
